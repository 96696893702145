export const Consent = ({
  checkAgree1,
  checkAgree2,
  setCheckAgree1,
  setCheckAgree2,
}) => {
  const handleAgree = (e) => {
    if (e === "ag1") {
      setCheckAgree1(!checkAgree1);
    } else if (e === "ag2") {
      setCheckAgree2(!checkAgree2);
    }
  };
  return (
    <div>
      <div className="mt-10 flex font-light-mon">
        <div>
          <input
            id="default-checkbox"
            type="checkbox"
            checked={checkAgree1}
            value={checkAgree1}
            onClick={(e) => handleAgree("ag1")}
            className="checkbox-confirm-register w-3 h-3 text-blue-600"
          />
        </div>
        <label htmlFor="check" className="text-[#333333] mr-[10px] text-[13px]">
          {" "}
        </label>
        <label
          htmlFor="check"
          className={
            "corlor-text-darkblack font-semibold-mon text-[13px] font-regular-mon"
          }
        >
          Tôi đồng ý với các Điều khoản và Điều kiện khi tham gia chương trình. 
        </label>
      </div>

      <div className="mt-5 flex font-light-mon">
        <div>
          <input
            id="default-checkbox"
            type="checkbox"
            checked={checkAgree2}
            value={checkAgree2}
            onClick={(e) => handleAgree("ag2")}
            className="checkbox-confirm-register w-3 h-3 text-blue-600"
          />
        </div>
        <label htmlFor="check" className="text-[#333333] mr-[10px] text-[13px]">
          {" "}
        </label>
        <label
          htmlFor="check"
          className={
            "corlor-text-darkblack font-semibold-mon text-[13px] font-regular-mon"
          }
        >
          Khi đánh dấu vào ô này, Tôi đồng ý cho công ty Kido và công ty cung
          cấp dịch vụ được Kido ủy quyền là công ty TNHH Truyền Thông MVC được
          sử dụng, lưu trữ các thông tin cá nhân do tôi cung cấp, trong hoặc
          ngoài lãnh thổ Việt Nam, vào mục đích trao giải thưởng, báo cáo, quản
          lý chương trình khuyến mại. Kido và công ty dịch vụ được ủy quyền sẽ
          lưu trữ các thông tin cá nhân trong thời hạn phù hợp với qui định của
          pháp luật. Để được tiếp cận, cập nhật, điều chỉnh thông tin cá nhân,
          Tôi sẽ liên hệ Kido và công ty được ủy quyền là công ty TNHH Truyền
          Thông MVC (http://mvc.com.vn) theo địa chỉ: 48 Hoa Mai, Phường 2, Quận
          Phú Nhuận, Tphcm. Điện thoại: 02836223393
        </label>
      </div>
    </div>
  );
};
