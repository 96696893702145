import React, { useRef, useEffect, useState } from "react";
import uuid from "react-uuid";
import { format } from "date-fns";
import { handleChangeImage } from "../../utils/compressImage";
import { ocrServices } from "../../services/apiService/ocrServices";
import { userServices } from "../../services/apiService/userServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ImageWithTimestamp = ({
  back,
  base64Image,
  checkLast,
  id,
  setLoading,
}) => {
  console.log("hello");
  const navigation = useNavigate();
  const canvasRef = useRef(null);
  let [ocrEndpoint, setOcrEndpoint] = useState();
  const [imageFile, setImageFile] = useState(undefined);
  const witeTimeStampInImage = (lat, long) => {
    if (base64Image) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Create a new image with the base64 data
      const image = new Image();
      image.src = base64Image;
      // Draw the image onto the canvas
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        // Add timestamp
        const timestamp = new Date().toLocaleString();
        ctx.font = "40px Arial";
        ctx.fillStyle = "white";
        ctx.fillText(timestamp, 10, 80);
        ctx.fillText(lat, 10, 130);
        ctx.fillText(long, 10, 180);
        canvas.toBlob(async (blob) => {
          let file = await new File([blob], "fileName.jpg", {
            type: "image/jpeg",
          });
          console.log(file);
          const imageFile = handleChangeImage(file);
          console.log("image file: ", file);
          let formDataGCS = new FormData();
          formDataGCS.append("file", file);
          const fileName =
            uuid() +
            "_" +
            format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
            "_" +
            file.name;
          formDataGCS.append("fileName", fileName);
          formDataGCS.append("ocrBase", ocrEndpoint);
          setImageFile(file);
          ocrServices
            .uploadImageToOCR(formDataGCS)
            .then((res) => {
              console.log(res.data.public_url);
              console.log(a);
              console.log([...a, res.data.public_url]);
              localStorage.setItem(
                "IMAGE_BIGGIFT",
                JSON.stringify([...a, res.data.public_url])
              );

              // if (checkLast) {
              const params = {
                so_id: id,
                list_link: [...a, res.data.public_url],
              };
              console.log(params);
              userServices
                .postUpdateImage(params)

                .then((res) => {
                  console.log(res);
                  if (checkLast) {
                    localStorage.removeItem("WHEEL_PHANTHUONG");
                    navigation(`/list-gift`);
                  } else {
                    navigation(`/${back}/${id}`);
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                })
                .finally(() => {
                  localStorage.removeItem("IMAGE_BIGGIFT");
                });
              // } else {
              //   navigation(`/${back}/${id}`);
              //   setLoading(false);
              // }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              localStorage.removeItem("IMAGE_BIGGIFT");
              // setLoadTimeStamp(false);
            });
        }, "image/jpeg");
      };
      const imgData = image.src;
      const a = JSON.parse(localStorage.getItem("IMAGE_BIGGIFT")) || [];
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          witeTimeStampInImage(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          witeTimeStampInImage("", "");
          console.log(error.message);
        }
      );
    } else {
      witeTimeStampInImage("", "");
      console.log("Geolocation is not supported by this browser.");
    }
  }, [base64Image]);
  return (
    <div>
      {imageFile ? (
        <img src={URL.createObjectURL(imageFile)} className="h-0" />
      ) : null}
      <canvas className="h-0" ref={canvasRef}></canvas>
    </div>
  );
};

export default ImageWithTimestamp;
