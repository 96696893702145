import INTRO_TOP from "../../assets/fontawesome/image/Intro_top.png";
import BUTTON_JOIN from "../../assets/fontawesome/image/btnjoin.png";
import INTRO_BOTTOM from "../../assets/fontawesome/image/Intro_bottom.png";
import KINGFOOD from "../../assets/fontawesome/image/kingfood.png";
import CIRCLE from "../../assets/fontawesome/image/circle.png";
import { useNavigate } from "react-router-dom";

export default function Intro() {
  const navigation = useNavigate();
  const handleRedirect = (e) => {
    navigation(e);
  };
  return (
    <div>
      <div className="relative">
        <img src={INTRO_TOP} />
      </div>
      <div className="relative -top-[1px]">
        <img src={INTRO_BOTTOM} />
        <div className="absolute top-5 w-full px-10">
          <div
            className="bg-[#ED2A26] flex justify-between pr-2 py-2 h-16 rounded-[48px]"
            onClick={() => handleRedirect("/login")}
          >
            <div className="uppercase font-bold-mon text-[22px] w-[250px] text-center text-white flex justify-center items-center">
              Tham gia ngay
            </div>
            <div className="bg-white rounded-[48px] px-5 flex items-center">
              <img src={BUTTON_JOIN} />
            </div>
          </div>
          {/* <div className="flex justify-around">
            <div onClick={() => handleRedirect("/login")}>
              <img src={KINGFOOD} />
            </div>
            <div onClick={() => handleRedirect("/login")}>
              <img src={CIRCLE} />
            </div>
          </div> */}
          <div className="px-5 flex justify-center">
            <div className="bg-[#F6D992] w-10/12 flex justify-between pr-2 mt-4 py-1 h-12 rounded-[48px]">
              <div className="font-bold-mon text-[16px] text-center text-[#5E4A1A] w-full flex items-center justify-center">
                Thể lệ chương trình
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
